import React, { useState } from 'react';
import Captcha from '../../components/Captcha';

function SignUp() {
  const CAPTCHA_LENGTH = 6;

  function generateCaptcha() {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < CAPTCHA_LENGTH; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return captcha;
  }
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [input, setInput] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setCaptcha(generateCaptcha());
    setInput('');
    setIsValid(false);
    setIsVisible(true);
  }

  return (
      <form
          onSubmit={handleSubmit}
          className='max-w-md mx-auto overflow-hidden bg-white rounded-md shadow-lg sm:max-w-lg'
      >
        <div className='p-6 space-y-6'>
          <Captcha
              captcha={captcha}
              input={input}
              setInput={setInput}
              isValid={isValid}
              setIsValid={setIsValid}
              CAPTCHA_LENGTH={CAPTCHA_LENGTH}
          />
        </div>
        <div>
          <button
              type='submit'
              className='w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            Submit
          </button>
        </div>
        <p className={`text-center text-green-500 top-10 ${!isVisible && 'hidden'}`}>
          nigga
        </p>
      </form>
  );
}

export default SignUp;
