import SignUp from './pages/Signup';
import Navbar from "./pages/Navbar";
import React from "react";


function App() {
  return (
    <div className='bg-gray-200'>
        <Navbar />
        <div className='flex flex-col items-center justify-center w-full h-screen bg-gray-200'>
               <SignUp />
        </div>
    </div>
  );
}

export default App;
